import React from 'react'
import './button.scss'

type Props = {
  children: React.ReactNode
  onClick?: () => void
  variant?: 'default' | 'primary' | 'secondary' | 'ghost' | 'light'
}

export const Button = ({ onClick, children, variant = 'default' }: Props) => {
  return (
    <button className={`ui-button ui-button-${variant}`} onClick={onClick}>
      {children}
    </button>
  )
}
