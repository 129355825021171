import { Modal, ModalBody, ModalHeader } from 'ui/modal'
import { PriorityHeaderTag } from 'ui/priority-header-tag'
import { Row } from 'ui/row'
import { IconButton } from 'ui/icon-button'
import { Input } from 'ui/input'
import { ImageGallery } from 'ui/image-gallery'
import { Column } from 'ui/column'
import { Button } from 'ui/button'
import { Select } from 'ui/select'
import { DatePicker } from 'ui/date-picker'
import { FileUploader, UploadableFile } from 'ui/file-uploader'
import React, { useEffect, useState } from 'react'

export const Ui = () => {
  const hostname = window.location.hostname
  if (hostname !== 'localhost' && hostname !== 'test.asseti.co') {
    return null
  }

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selected, setSelected] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState<UploadableFile[]>([])

  useEffect(() => {
    const interval = setInterval(() => {
      setUploadedFiles((files) =>
        files.map((file) => {
          if (file.uploadState.state === 'loading') {
            const newProgress = Math.min(file.uploadState.progress + Math.random() * 10, 100)
            if (newProgress >= 100) {
              return { ...file, uploadState: { state: 'loaded' } }
            } else {
              return { ...file, uploadState: { state: 'loading', progress: newProgress } }
            }
          }
          return file
        })
      )
    }, 350)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div
        className='subheader-dark mt-5 mb-5'
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Sign in to your account
      </div>

      <Modal
        size={'small'}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <PriorityHeaderTag priority={'high'}>High Priority</PriorityHeaderTag>

        <Row spacing={8}>
          <IconButton color={'red'} onClick={() => {}} icon={'fa-light fa-trash'} size={'small'} />
          <IconButton color={'green'} onClick={() => {}} icon={'fa-light fa-trash'} size={'medium'} />
          <IconButton onClick={() => {}} icon={'fa-light fa-trash'} size={'large'} />
          <Input placeholder='Component Type' readOnly value={'Colorbond Sheeting'} />
        </Row>

        <ImageGallery
          imagesPerRow={3}
          maxImages={3}
          images={[
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0',
              alt: 'Forest Path',
              title: 'A serene forest path',
            },
            {
              src: 'https://images.unsplash.com/photo-1519681393784-d120267933ba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGNpdHl8ZW58MHx8fHwxNjY4MzczMTM4&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'City Skyline',
              title: 'A bustling city skyline',
            },
            {
              src: 'https://images.unsplash.com/photo-1519681393784-d120267933ba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGNpdHl8ZW58MHx8fHwxNjY4MzczMTM4&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'City Skyline',
              title: 'A bustling city skyline',
            },
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fHNlYXxlbnwwfHx8fDE2NjgzNzMxMzg&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'Ocean Waves',
              title: 'Waves crashing on the shore',
            },
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fHNlYXxlbnwwfHx8fDE2NjgzNzMxMzg&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'Ocean Waves',
              title: 'Waves crashing on the shore',
            },
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fHNlYXxlbnwwfHx8fDE2NjgzNzMxMzg&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'Ocean Waves',
              title: 'Waves crashing on the shore',
            },
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fHNlYXxlbnwwfHx8fDE2NjgzNzMxMzg&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'Ocean Waves',
              title: 'Waves crashing on the shore',
            },
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fHNlYXxlbnwwfHx8fDE2NjgzNzMxMzg&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'Ocean Waves',
              title: 'Waves crashing on the shore',
            },
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fHNlYXxlbnwwfHx8fDE2NjgzNzMxMzg&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'Ocean Waves',
              title: 'Waves crashing on the shore',
            },
            {
              src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDV8fHNlYXxlbnwwfHx8fDE2NjgzNzMxMzg&ixlib=rb-4.0.3&q=80&w=400',
              alt: 'Ocean Waves',
              title: 'Waves crashing on the shore',
            },
          ]}
        />

        <ModalHeader>
          <h5>Tag an Issue</h5>
        </ModalHeader>

        <ModalBody>
          <Column spacing={16}>
            <Column spacing={16}>
              <p>Easily tag and categorise issues or defects to enable tracking and resolution.</p>
              <Column spacing={32}>
                <Button variant='light'>
                  <i className='fa-regular fa-arrow-rotate-right'></i> Reselect issue area/location
                </Button>

                <Input label={'Location'} placeholder='Location' value={'External'} clearable />
              </Column>
              <Input label={'Component Type'} placeholder='Component Type' readOnly value={'Colorbond Sheeting'} />
              <Select
                value={selected}
                onChange={(e) => {
                  setSelected(e)
                }}
                label={'Component Type'}
                options={[
                  {
                    value: '1',
                    label: 'Option 1',
                  },
                  {
                    value: '2',
                    label: 'Option 2',
                  },
                  {
                    value: '3',
                    label: 'Option 3',
                    disabled: true,
                  },
                ]}
              />

              <DatePicker
                label='Select Date'
                selectedDate={selectedDate}
                onChange={setSelectedDate}
                placeholder='Choose a date'
                helperText='Please select a date.'
              />
            </Column>

            <FileUploader
              files={uploadedFiles}
              setFiles={setUploadedFiles}
              maxSize={100 * 1024 ** 2}
              accept={['jpeg', 'png']}
            />

            <Button
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Submit Issue / Defect
            </Button>
          </Column>
        </ModalBody>
      </Modal>
    </>
  )
}
