import React, { useEffect, useState } from 'react'
import './instant-assess.scss'
import { PersonalInfo } from 'instant-assess/personal-info'
import { CompanyInfo } from 'instant-assess/company-info'
import { Welcome } from 'instant-assess/welcome'
import { SignUpSuccess } from 'instant-assess/signup-success'
import {
  AnalyticsPanelPortfolioSnapshot,
  Drawer,
  DrawerPosition,
  DrawerState,
  getTracking,
  notReachable,
  object,
  string,
  useForm,
  useQuery,
  SimpleBar,
} from 'components'
import {
  InstantAssessmentSummaryDemoQuery,
  InstantAssessmentSummaryDemoQueryVariables,
  MeasurementSystem,
  SignUpInstantAssessMutation,
  SignUpInstantAssessRequestT,
} from 'models'
import mutation from './mutation-sign-up-instant-assess.gql'
import { AuthError } from 'auth/auth-error'
import { useUser } from 'base'
import { MainBarImpl } from '~/home/main-bar'
import { Marker, useCesium } from '~/state'
import { Config } from '~/config'
import IA_SUMMARY_QUERY from './query-instant-assessment-summary.gql'
import { SitesListIASite } from '~/sites/sites-list-ia'
import { expandRect } from '~/state/utils'
import CHEVRONS from '../images/chevrons-right.svg'
import { Modal } from 'ui'

type State =
  | {
      type: 'get_started'
    }
  | { type: 'personal_info' }
  | { type: 'company_info' }
  | { type: 'signup_success' }

type Org = {
  orgName: string
  ordId: string
}

export type SignUpFormType = SignUpInstantAssessRequestT & { industry: string } & { leadSource: string }

interface InstantAssessProps {
  org: Org
}

const InstantAssess = (props: InstantAssessProps) => {
  const [state, setState] = useState<State>({ type: 'get_started' })
  const user = useUser()

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error } = useForm<
    SignUpInstantAssessMutation,
    SignUpFormType,
    SignUpInstantAssessRequestT
  >({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      orgID: props.org.ordId,
      jobTitle: '',
      marketingConsent: false,
      phoneNumber: '',
      industry: '',
      leadSource: '',
    },
    validationSchema: object().shape({
      firstName: string().max(15, 'Must be 15 characters or less').required('Required'),
      lastName: string().max(20, 'Must be 20 characters or less').required('Required'),
      email: string().email('Invalid email address').required('Required'),
      password: string()
        .required('Required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\~\`])(?=.{8,})/,
          'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'
        ),
      jobTitle: string().max(15, 'Must be 20 characters or less').required('Required'),
      phoneNumber: string().matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Phone number is not valid'
      ),
      industry: string().required('Required'),
      leadSource: string().required('Required'),
    }),
    mutation,
    mapInput: (input: SignUpFormType): SignUpInstantAssessRequestT => {
      delete input['industry']
      delete input['leadSource']
      return input
    },
    onSuccess: (result: SignUpInstantAssessMutation) => {
      console.log('result', result)
      if (result.signUpInstantAssess.token) {
        getTracking().event({
          category: 'Form',
          action: `User signed up for Instant Assess`,
          label: 'InstantAssess',
          industry: values.industry,
          leadSource: values.leadSource,
        })
        user.setSignIn(result.signUpInstantAssess)
        user.setToken(result.signUpInstantAssess.token)
        setState({ type: 'signup_success' })
      }
    },
  })

  return (
    <div className='instant-assess-layout'>
      {(() => {
        switch (state.type) {
          case 'get_started':
            return (
              <Welcome
                onMsg={(onMsg) => {
                  switch (onMsg.type) {
                    case 'get_started_clicked':
                      setState({ type: 'personal_info' })
                      break

                    default:
                      return notReachable(onMsg.type)
                  }
                }}
              />
            )

          case 'personal_info':
            return (
              <PersonalInfo
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                onMsg={(onMsg) => {
                  switch (onMsg.type) {
                    case 'final_step_clicked':
                      setState({ type: 'company_info' })
                      break
                    case 'back_clicked':
                      setState({ type: 'get_started' })
                      break

                    default:
                      return notReachable(onMsg)
                  }
                }}
              />
            )

          case 'company_info':
            return (
              <CompanyInfo
                isSubmitting={isSubmitting}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                orgName={props.org.orgName}
                onMsg={(onMsg) => {
                  switch (onMsg.type) {
                    case 'finish_clicked':
                      handleSubmit()
                      break
                    case 'back_clicked':
                      setState({ type: 'personal_info' })
                      break

                    default:
                      return notReachable(onMsg)
                  }
                }}
              />
            )

          case 'signup_success':
            return (
              <SignUpSuccess
                onMsg={(onMsg) => {
                  switch (onMsg.type) {
                    case 'view_your_portfolio':
                      window.location.href = '/'
                      break

                    default:
                      return notReachable(onMsg.type)
                  }
                }}
              />
            )

          default:
            return notReachable(state)
        }
      })()}
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
    </div>
  )
}

const useBase64UrlParam = (base64Org: string): Org => {
  if (!base64Org) {
    window.location.href = '/'
  }
  const bits = atob(base64Org).split(':')
  return {
    orgName: bits[0],
    ordId: bits[1],
  }
}

export const InstantAssessContainer = () => {
  const user = useUser()
  const token = new URLSearchParams(window.location.search).get('t')
  user.setToken(token)
  const org = useBase64UrlParam(new URLSearchParams(window.location.search).get('o'))

  const iaQuery = useQuery<InstantAssessmentSummaryDemoQuery, InstantAssessmentSummaryDemoQueryVariables>(
    IA_SUMMARY_QUERY,
    {
      variables: {
        input: {
          measurementSystem: MeasurementSystem.Imperial,
        },
      },
    }
  )

  const [showIASignUp, setShowIASignup] = useState(false)

  useEffect(() => {
    if (showIASignUp) {
      return
    }

    function onClick(e: PointerEvent) {
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()

      const allowedElement = document.getElementById('cesiumContainer')
      if (allowedElement && allowedElement.contains(e.target as Node)) {
        return
      }

      setShowIASignup(true)
    }

    document.body.addEventListener('click', onClick, true)

    return () => {
      document.body.removeEventListener('click', onClick, true)
    }
  }, [showIASignUp])

  const map = useCesium()

  useEffect(() => {
    if (map.initialized) {
      return
    }

    map.initialize(Config.DefaultMapRectangle.clone())
  }, [])

  const hasLoadedIASummary = typeof iaQuery.data?.instantAssessmentSummaryDemo === 'object'
  const summary = hasLoadedIASummary && iaQuery.data.instantAssessmentSummaryDemo

  useEffect(() => {
    if (!map.initialized || !summary) {
      return
    }

    const markers: Marker[] = []
    for (const site of summary.locations) {
      markers.push(
        map.addClusteredMarker(
          site.name,
          Cesium.Cartesian3.fromDegrees(site.longitude, site.latitude),
          site.name,
          '',
          () => {
            setShowIASignup(true)
          }
        )
      )
    }

    map.jumpTo(getBoundsForSites(summary.locations))
  }, [map.initialized, summary])

  return (
    <div className='home-layout'>
      <MainBarImpl />
      <Drawer state={DrawerState.Normal} position={DrawerPosition.Left} className='main-panel'>
        <div className='sites-header'>
          <div className='sites-header-top'>
            <div className='sites-header-top-title-container'>
              <span className='sites-header-top-org-title'>
                <div className='org-dropdown-content'>
                  <div className='dropdown org-dropdown'>
                    <button className={`btn dropdown-toggle`} type='button' id={`dropdownMenuButton-org`}>
                      {org.orgName}
                    </button>
                  </div>
                </div>
              </span>
            </div>
            <div className='sites-header-top-expand'>
              <img src={CHEVRONS} alt='Expand Drawer' className='sites-header-top-expand-expanded' />
            </div>
          </div>
          <div className='sites-header-middle'>
            <i className='sites-header-middle-add material-icons'>add</i>
            <h6 className='sites-header-middle-sites-title'>Sites</h6>
          </div>
          <div className='sites-header-bottom' style={{ zIndex: 'unset' }}>
            <div className='sites-header-bottom-sites-search'>
              <form autoComplete='off' className='form-group'>
                <div className='input-group'>
                  <input type='text' className='form-control' placeholder='Search' />
                  <div className='input-group-append'>
                    <span className='input-group-text'>
                      <span className='material-icons'>search</span>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='sites-list' id='sites-list'>
          <div className='sites-list-ia'>
            <div className='sites-list-ia-list'>
              <SimpleBar>
                {hasLoadedIASummary && summary.sites.map((s) => <SitesListIASite key={s.siteName} site={s} />)}
                {hasLoadedIASummary && (
                  <div className='sites-list-ia-list-extra'>
                    + {summary.numSitesTotal - summary.numSites} other sites identified
                  </div>
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </SimpleBar>
            </div>
          </div>
        </div>
      </Drawer>

      <div id='mapBoxContainer'>
        <div id='cesiumContainer' />
      </div>
      {hasLoadedIASummary && (
        <Drawer state={DrawerState.Normal} position={DrawerPosition.Right} transparent>
          <div className='activity-container'>
            <div className='main-panel' style={{ paddingLeft: '0.5rem' }}>
              <AnalyticsPanelPortfolioSnapshot summary={iaQuery.data?.instantAssessmentSummaryDemo} />
            </div>
          </div>
        </Drawer>
      )}
      <Modal size='small' backgroundVariant='glass' isOpen={showIASignUp} showCloseButton={false}>
        <InstantAssess org={org} />
      </Modal>
    </div>
  )
}

function getBoundsForSites(
  sites: InstantAssessmentSummaryDemoQuery['instantAssessmentSummaryDemo']['locations']
): Cesium.Rectangle {
  if (sites.length === 0) {
    return Config.DefaultMapRectangle.clone()
  }

  const points: Cesium.Cartographic[] = []
  for (const site of sites) {
    points.push(Cesium.Cartographic.fromDegrees(site.longitude, site.latitude))
  }

  const rect = Cesium.Rectangle.fromCartographicArray(points)
  return expandRect(rect, 0.025, 0.3)
}
