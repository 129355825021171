import React from 'react'
import './icon-button.scss'

type Props = {
  onClick: () => void
  icon: string
  label?: string
  size?: 'small' | 'medium' | 'large'
  color?: string
}

export const IconButton = ({ onClick, icon, label, size = 'medium', color }: Props) => {
  return (
    <button onClick={onClick} className={`ui-icon-button ui-icon-button--${size}`} aria-label={label}>
      <i className={icon} style={{ color }} aria-hidden='true'></i>
    </button>
  )
}
